button.IconButton {
  width: 2.25rem;
  height: 2.25rem;
  background: none;
  outline: none;
  border: none;
  padding: .25rem;
  padding-left: 0;
  @include cursorDot;
  svg{
    width: 100%;
    height: 100%;
  }
}
