.Carousel-Wrapper {
  position: relative;
  background: #000;
  padding-top: 50%;
  @include media-breakpoint-down(lg) {
    padding-top: 66%;
  }
}

.Carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Carousel.CursorsPrev {
  cursor: url(/static-assets/arrow-w.png) 16 16, auto;
  cursor: -webkit-image-set(
                  url(/static-assets/arrow-w.png) 1x,
                  url(/static-assets/arrow-w@2x.png) 2x
  ) 16 16, auto,
}

.Carousel.CursorsNext {
  cursor: url(/static-assets/arrow-e.png) 16 16, auto;
  cursor: -webkit-image-set(
                  url(/static-assets/arrow-e.png) 1x,
                  url(/static-assets/arrow-e@2x.png) 2x
  ) 16 16, auto,
}


.Carousel .swiper-container {
  height: 100%;
  width: 100%;
}

.Carousel .swiper-slide {
  width: auto;
  height: 100%;
}

.Carousel-Slide {
  color: #ffffff;
  width: auto;
  padding: 1rem;
  padding-right: 0;
  height: 100%;

  &.isActive {
    border: 2px solid red;
  }

  &.last {
    padding-right: 1rem;
  }
}

.Carousel-Slide-Bild {
  img {
    display: block;
    height: 100%;
    width: 100%;
  }
}

.Carousel-Slide-Video {
}

.Carousel-Slide-IframeWrapper {
  position: relative;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  div, iframe {
    width: 100%;
    height: 100%;
  }

  iframe {
    pointer-events: none !important;
  }
}

div.Carousel-Slide-IframeControls {
  position: absolute;
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
}