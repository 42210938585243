.Header{
  user-select: none;
}

.Logo{
  width: 30rem;
  @include media-breakpoint-down(md){
   width: 100%; 
  }
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}