.Intro{
  position: relative;
  transition: height 600ms ease;
  overflow: hidden;
  background: #000000;

  &.opened{
  }

  .Intro-Stage{
    padding-top: 45%;
    position: relative;
    @include media-breakpoint-down(lg) {
      padding-top: 55%;
    }
  }

  .Intro-Content{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  img{
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}