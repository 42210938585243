.fade-enter {
  opacity: 0.01;
}

.fade-enter-active{
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms linear;
}




.inflate-enter {
  opacity: 0.01;
}

.inflate-enter-active{
  opacity: 1;
  transition: opacity 900ms linear;
}

.inflate-exit {
  opacity: 1;
}

.inflate-exit-active {
  opacity: 0.01;
  transition: opacity 900ms linear;
}