
html {
  font-size: 11px;
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }
}

html {

  overflow-y: scroll;

  font-variant-ligatures: discretionary-ligatures;
  -moz-font-feature-settings: "dlig";
  -webkit-font-feature-settings: "dlig";
  font-feature-settings: "dlig";

  @include cursorDot;

}


.App {
  @include media-breakpoint-up(sm) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 3.5rem;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

* {
  cursor: inherit;
}

.fit {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.clickable {
  user-select: none;
  cursor: inherit;
}

.lead {
  font-size: 1.6rem;
}

a:active, a:focus {
  outline: 0;
}

.hover-green {
  &:hover, a:hover {
    color: #00fd99;
  }
}

.redactor {
  a:hover {
    color: #00fd99;
  }
}
