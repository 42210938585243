.Item {
  position: relative;
}

.ItemHeader {
  font-variant-numeric: tabular-nums; /* high-level property          */
  -moz-font-feature-settings: 'tnum'; /* low-level (old Firefox)      */
  -webkit-font-feature-settings: 'tnum'; /* low-level (old Webkit)       */
  font-feature-settings: 'tnum' on;

  .ItemTitle {
    display: block;
  }

  .ItemCategories {
    display: none;
  }

  &:hover, &.opened {
    background: #000;
    color: white;
  }

  &:hover {
    .ItemCategories {
      display: block;
    }
    .ItemTitle {
      display: none;
    }
  }
}

.ItemContentContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height 600ms ease;
}

.ItemContent {

}

.ItemMedia {
  background: #000;
}

.ItemInfoBody {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 480px;
  width: 100%;
  overflow-y: auto;
  z-index: 100;
  transform: translateX(-100%);
  transition: transform 300ms ease;
}

.ItemInfoHeader {
  background: #000;
  text-decoration: underline;
  color: #ffffff;

  &:hover{
    color: rgb(0,253,153);
  }
}

.ItemInfo.open .ItemInfoBody {
  transform: translateX(0%);
}

.ItemInfoBody .Redactor{
  a:hover{
    color: rgb(0,253,153);
  }
}

.VisitorCount {
  font-variant-numeric: tabular-nums; /* high-level property          */
  -moz-font-feature-settings: 'tnum'; /* low-level (old Firefox)      */
  -webkit-font-feature-settings: 'tnum'; /* low-level (old Webkit)       */
  font-feature-settings: 'tnum' on;
}
